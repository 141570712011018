<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div
        class="uk-background-default breadcrumb"
        style="padding: 15px 35px;border-bottom: 1px solid rgb(229, 229, 229);"
      >
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h5 uk-margin-remove">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="uk-margin-small-right">
              <path fill-rule="evenodd" d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z" clip-rule="evenodd" />
            </svg> {{ headerLabel }}
            </h3>
          </div>
          <div>
            <a href="#" @click.prevent="$router.go(-1)" class="uk-button uk-button-text">Abbrechen</a>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-container-expand">
      <div class="uk-section uk-section-xsmall uk-margin-large-bottom">
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-4@l">
            <h4 class="uk-margin-small">Daten</h4>
            <div class="uk-text-muted uk-text-small">
              Die Daten des Technikers
            </div>
          </div>
          <div class="uk-width-expand">
            <ValidationObserver ref="observer">
              <div class="uk-card uk-card-default">
                <div class="uk-card-body">
                  <div class="uk-width-xlarge@l">
                    <form class="uk-form-stacked" @submit.prevent="handleSubmit">
                      <div class="uk-grid uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Firma</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.company"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Anrede</label>
                          <div class="uk-form-controls">
                            <select
                              class="uk-select uk-border-rounded"
                              v-model="form.salutation"
                            >
                              <option value="Herr">Herr</option>
                              <option value="Frau">Frau</option>
                            </select>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Vorname*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="firstname"
                              v-slot="{ errors }"
                            >
                              <input
                                class="uk-input uk-border-rounded"
                                type="text"
                                v-model="form.firstname"
                                :class="{ 'uk-form-danger': errors[0] }"
                              />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Nachname*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="lastname"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.lastname"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">ASP</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.asp"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Straße*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="street"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.street"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">PLZ*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="zipcode"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.zipcode"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-2@l">
                          <label class="uk-form-label">Ort*</label>
                          <div class="uk-form-controls">
                            <ValidationProvider
                              :rules="'required'"
                              name="city"
                              v-slot="{ errors }"
                            >
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.city"
                              :class="{ 'uk-form-danger': errors[0] }"
                            />
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">E-Mail</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.email"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Telefon</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.phone"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Fax</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.fax"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Mobil</label>
                          <div class="uk-form-controls">
                            <input
                              class="uk-input uk-border-rounded"
                              type="text"
                              v-model="form.mobile"
                            />
                          </div>
                        </div>
                        <div class="uk-width-1-1">
                          <label class="uk-form-label">Unterschrift</label>
                          <div class="uk-form-controls">
                            <div class="uk-placeholder"> 
                              <VueSignaturePad v-if="!form.signature" width="100%" height="200px" ref="signaturePad" id="signature" :options="{penColor: '#00f', maxWidth: 1}" />
                              <img :src="form.signature" class="uk-height-small" v-else> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="uk-card-footer">
                  <div class="uk-text-right">
                    <button
                      class="uk-button uk-button-primary uk-border-rounded uk-margin-right"
                      type="submit"
                      :disabled="sending"
                      @click.prevent="handleSubmit(true)"
                    >
                      Speichern und Schließen
                    </button>
                    <button
                      class="uk-button uk-button-primary uk-border-rounded"
                      type="submit"
                      :disabled="sending"
                      @click.prevent="handleSubmit(false)"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TechnicianService from '@/services/technician.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Pflichtfeld',
})

export default {
  data() {
    return {
      sending: false,
      form: {
        id: null,
        company: '',
        salutation: 'Herr',
        firstname: '',
        lastname: '',
        asp: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        phone: '',
        fax: '',
        mobile: '',
      },
    }
  },
  mounted() {
    if (typeof this.$route.params.id !== 'undefined') {
      this.getTechnician(this.$route.params.id)
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    headerLabel() {
      let label = 'Messtechniker erstellen'
      if(this.form.id) {
        if(this.form.company) {
          label = this.form.company
        } else {
          label = `${this.form.firstname} ${this.form.lastname}`
        }
      }
      return label
    },
  },
  methods: {
    getTechnician(id) {
      TechnicianService.get(id).then((response) => {
        this.form = response.data
      })
    },
    async handleSubmit(close) {
      const observer = this.$refs.observer
      const success = await observer.validate()

      if (!success) {
        for (const key of Object.keys(observer.fields)) {
          if (observer.fields[key].invalid) {
            window.scroll({ top: (observer.refs[key].$el.offsetTop - 50), left: 0, behavior: 'smooth' });
            return
          }
        }
      }

      this.store(close)
    },
    async store(close) {
      this.sending = true
      let response = null
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty) {
        this.form.signature = data
      }
      if (this.form.id) {
        try {
          response = await TechnicianService.update(this.form.id, this.form)
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          response = await TechnicianService.store(this.form)
        } catch (error) {
          console.log(error)
        }
      }

      if (response) {
        this.getTechnician(response.data.id)
      }

      this.sending = false

      iziToast.success({
        title: 'OK',
        message: 'Messtechniker gespeichert!',
        timeout: 3000,
        position: 'bottomRight',
        transitionIn: 'fadeInLeft',
      })

      if(close) {
        this.$router.push({
          name: 'technician'
        })
      }
    },
  },
}
</script>
