import Api from './api.service'

export default {
  getList (params) {
    return Api().get('/technician', { params })
  },
  get (id) {
    return Api().get(`/technician/${id}`)
  },
  store (payload) {
    return Api().post('technician', payload)
  },
  update (id, payload) {
    return Api().post(`technician/${id}`, payload)
  },
  delete (id) {
    return Api().delete(`technician/${id}`)
  }
}
